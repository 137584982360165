<template>
  <component
    :is="as"
    :class="[
      'heading',
      `heading--size-${size}`,
    ]"
  >
    <slot />
  </component>
</template>

<script>
import { includes } from 'lodash';

const validElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span'];
const validSizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];

export default {
  props: {
    as: {
      type: String,
      default: 'h1',
      validator: (value) => includes(validElements, value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => includes(validSizes, value),
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 16px;
  line-height: normal;

  &--size-xs { // h6 in Figma
    font-size: 15.72px;
    line-height: 23.58px;
  }

  &--size-sm { // h5 in Figma
    font-size: 17.64px;
    line-height: 26.46px;
  }

  &--size-md { // h4 in Figma
    font-size: 19.8px;
    line-height: 29.7px;
  }

  &--size-lg { // h3 in Figma
    font-size: 22.22px;
    line-height: 33.33px;
  }

  &--size-xl { // h2 in Figma
    font-size: 24.95px;
    line-height: 37.43px;
  }

  &--size-2xl { // h1 in Figma
    font-size: 28px;
    line-height: 42px;
  }
}
</style>
