<template>
  <button
    ref="rootElement"
    :class="[
      'sidebar-button',
      { 'sidebar-button--is-selected': selected },
    ]"
    :title="title"
    :disabled="disabled"
  >
    <div
      class="sidebar-button__indicator"
    />
    <div class="sidebar-button__content">
      <slot />
    </div>
  </button>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'scroll-to': {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const scrollTo = () => {
      if (props.selected) {
        emit('scroll-to', rootElement.value.offsetTop);
      }
    };
    onMounted(scrollTo);
    watch(() => props.isSelected, scrollTo);

    return {
      rootElement,
    };
  },
};
</script>

<style lang="scss">
.sidebar-button {
  display: flex;
  align-items: center;
  height: 32px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  &__indicator {
    flex: 0;
    min-width: 4px;
    height: 100%;
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
  }

  &__content {
    width: 100%;
    height: 100%;
    margin-left: 4px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  &:hover {
    .sidebar-button__indicator,
    .sidebar-button__content {
      background-color: #f0f0f0;
    }
  }

  &--is-selected,
  &--is-selected:hover {
    .sidebar-button__indicator {
      background-color: #003C3C;
    }

    .sidebar-button__content {
      background-color: rgba(#003C3C, 0.1);
    }
  }
}
</style>
