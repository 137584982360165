<template>
  <transition name="sidebar-slide">
    <div
      :class="[
        'sidebar',
        { 'sidebar--is-expanded': expanded },
      ]"
    >
      <button
        class="sidebar__collapse-button"
        @click="toggleExpanded"
      >
        <Icon
          :name="expandIcon"
        />
      </button>
      <div
        v-if="expanded"
        class="sidebar__content"
      >
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from 'vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:expanded': {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const expandIcon = computed(() => (props.expanded ? 'chevron-left' : 'chevron-right'));
    const toggleExpanded = () => {
      emit('update:expanded', !props.expanded);
    };

    return {
      expandIcon,
      toggleExpanded,
    };
  },
};
</script>

<style lang="scss">
.sidebar {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  padding: 32px 0 0;
  gap: 8px;
  width: 16px;
  flex-shrink: 0;
  position: relative;
  border-right: 1px solid #F0F0F0;
  z-index: 100;

  &--is-expanded {
    width: 220px;
  }

  &__collapse-button {
    background-color: #fff;
    position: absolute;
    top: 8px;
    right: -12px;
    width: 24px;
    height: 24px;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 3px;
    cursor: pointer;

    .icon {
      stroke: #000;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px #A2BFFF;
      outline: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
